import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import ProTip from './ProTip';
import Home from './Home'
import ProductCategories from './modules/components/ProductCategories';
import ProductSmokingHero from './modules/components/ProductSmokingHero';
import AppFooter from './modules/components/AppFooter';
import ProductHero from './modules/components/ProductHero';
import ProductValues from './modules/components/ProductValues';
import ProductHowItWorks from './modules/components/ProductHowItWorks';
import ProductCTA from './modules/components/ProductCTA';
import AppAppBar from './modules/components/AppAppBar';
import withRoot from './modules/withRoot';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://tylerlcampbell.com/">
        TylerLCampbell . com
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}


export default function App() {
    return (
          
        <Container
            maxWidth="false"
            disableGutters
            sx={{ backgroundColor: 'gray', width: '100'}}
        >
            <AppAppBar />
            <ProductHero />
            <ProductValues />
            {/*<ProductCategories />
            <ProductHowItWorks />*/}
            <Copyright />
            
          
    </Container>
  );
}
