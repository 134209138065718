import * as React from 'react';
import Button from '../components/Button';
import Typography from '../components/Typography';
import ProductHeroLayout from '../components/ProductHeroLayout';
import Box from '@mui/material/Box';

const backgroundImage = "img/ai_fisherman.jpg";

export default function ProductHero() {
  return (
      <ProductHeroLayout>
          <Box align="center" sx={{ width: '100%', height: '5vh' }}>

          </Box>
            <Typography color="inherit" align="center" variant="h2" marked="center">
                Welcome
            </Typography>
          <Box align="center" sx={{ width: '100%', height: '2vh' }}>
              
          </Box>
          <Box align="center" sx={{ width: '75%' }}>
              <img width="100%" height="auto" style={{ alignContent: 'center' }} src={`${process.env.PUBLIC_URL}/${backgroundImage}`} />
          </Box>
    </ProductHeroLayout>
  );
}
