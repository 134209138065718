import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import AppBar from '../components/AppBar';
import Toolbar from '../components/Toolbar';
import Typography from '../components/Typography';
import Button from '../components/Button'


function AppAppBar() {
  return (
    <div width="100%">
          <AppBar position="fixed" padding="none">
              <Toolbar sx={{ justifyContent: 'left', color: 'primary' }}>
            </Toolbar>
        </AppBar>
    </div>
  );
}

export default AppAppBar;
